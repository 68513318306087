import React, { useEffect } from "react";
import { Button, Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "gatsby";
import HeaderExpanded from "../components/HeaderExpanded/HeaderExpanded";
import "../styles/demo.scss";
import { Helmet } from "react-helmet";

function Demo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Demo</title>
        <meta
          name="description"
          content="Request a Free MetWatch Grower Tools Demo"
        />
      </Helmet>
      <HeaderExpanded
        title="Request a Free MetWatch Grower Demo"
        image={process.env.GATSBY_S3_BUCKET_ASSETS + "images/grower_tools.jpg"}
      />
      <Container className="hp-padding-100 mb-5 text-center">
        <Container className="pb-5">
          <div>
            <p className="mb-4 hp-font-28 hp-title ">
              Interested in trying out our grower tools?
            </p>
          </div>
          <Button as={Link} to={"https://hortplus.metwatch.nz/signup"}>
            Get instant free trial of MetWatch now
          </Button>

          <div className="mt-4">
            <p>
              Alternativley please <Link to={"/contact"}>contact us</Link> to arrange a free no-obligation demo of the{" "}
              <b>MetWatch Grower Tools</b>.
            </p>
            <p>
              Demo includes standard suite of weather &amp; disease models and
              forecasts.
            </p>
          </div>
        </Container>
        <Container className="pt-5">
          <Row>
            <Col xs={12} md={4} className="px-4">
              <Image
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "icons/disease_risk_model_icon.svg"
                }
                alt="Weather Station Icon"
                className="color-hp-green mb-4"
              />
              <p className="mb-3 hp-font-28">Leading Disease Risk Models</p>
              <p>
                Access major disease risk models for Apples, Grapes, Vegetables
                and more. Models are powered by data and forecasts from our
                weather network.
              </p>
            </Col>
            <Col xs={12} md={4} className="px-4">
              <Image
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "icons/green_weather_station_icon.svg"
                }
                alt="Weather Station Icon"
                className="color-hp-green mb-4"
              />
              <p className="mb-3 hp-font-28">
                Connect Your Own Weather Station
              </p>

              <p>
                In addition to our national network of scientific-grade weather
                stations you can connect your own weather stations including{" "}
                <b>Harvest</b>, <b>Campbell Scientific</b>, and other makers.
              </p>
            </Col>
            <Col xs={12} md={4} className="px-4">
              <Image
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS +
                  "icons/data_visuals_icon.svg"
                }
                alt="Weather Station Icon"
                className="color-hp-green mb-4"
              />
              <p className="mb-3 hp-font-28">
                Data Visuals &amp; Tools Tailored for Growers{" "}
              </p>
              <p>
                Get daily &amp; hourly weather forecasts and summaries as well
                as a range of seasonal data tools and calculators.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Demo;
